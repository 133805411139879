exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-treatments-anti-wrinkle-js": () => import("./../../../src/pages/treatments/anti-wrinkle.js" /* webpackChunkName: "component---src-pages-treatments-anti-wrinkle-js" */),
  "component---src-pages-treatments-axillary-hyperhidrosis-js": () => import("./../../../src/pages/treatments/axillary-hyperhidrosis.js" /* webpackChunkName: "component---src-pages-treatments-axillary-hyperhidrosis-js" */),
  "component---src-pages-treatments-bio-remodelling-js": () => import("./../../../src/pages/treatments/bio-remodelling.js" /* webpackChunkName: "component---src-pages-treatments-bio-remodelling-js" */),
  "component---src-pages-treatments-dermal-filler-js": () => import("./../../../src/pages/treatments/dermal-filler.js" /* webpackChunkName: "component---src-pages-treatments-dermal-filler-js" */),
  "component---src-pages-treatments-fat-dissolving-js": () => import("./../../../src/pages/treatments/fat-dissolving.js" /* webpackChunkName: "component---src-pages-treatments-fat-dissolving-js" */),
  "component---src-pages-treatments-hay-fever-treatment-js": () => import("./../../../src/pages/treatments/hay-fever-treatment.js" /* webpackChunkName: "component---src-pages-treatments-hay-fever-treatment-js" */)
}

